import "./components/modal";
import "./components/tooltip";
import { initModals } from "./components/modal";
import { initTooltips } from "./components/tooltip";

import "./css/index.scss";
import { initDrawers } from "./components/drawer";
import { initTabs } from "./components/tabs";

window.addEventListener("DOMContentLoaded", () => {
  initModals();
  initTooltips();
  initDrawers();
  initTabs();
});
